import { Box, Breadcrumbs, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SourceIcon from '@mui/icons-material/Source';
import { useSelector } from 'react-redux';
import AppContext from '../../state/AppContext';
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { init, reset } from './store/edit-webhook-actions';
import EditWebhookGenerals from './EditWebhookGenerals';
import PolylineIcon from '@mui/icons-material/Polyline';
import EditWebhookRulesBindings from './EditWebhookRulesBindings';


const EditWebhook = (props) => {

    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { webhookId } = useParams()
    const [tab, setTab] = useState(0)
    const state = useSelector(state => state.pages.editWebhook)

    useEffect(() => {
        if (webhookId !== undefined) {
            init(project, webhookId)
        }

        return () => reset()
    }, [webhookId])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className="">
            <Header title={<span className="capitalize">{state.id || "New webhook"}</span>} backLink={`/projects/${project}/webhooks`} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Webhooks</span></Typography>
                    <Typography ><span className="text-gray-600">{webhookId}</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content">
                <div className="grid gap-8" >
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                        <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                        <Tab label="Rules Bindings" icon={<PolylineIcon />} iconPosition="start" />
                                        {
                                            //<Tab label="Interactions" disabled={state.new} icon={<ForumIcon />} iconPosition="start" />
                                        }

                                    </Tabs>
                                </Box>
                            </Box>
                            {tab === 0 && <EditWebhookGenerals />}
                            {tab === 1 && <EditWebhookRulesBindings project={project} webhookId={webhookId} />}
                            {
                                //tab === 1 && <EditIncidentInteractions />
                            }
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )

}


export default EditWebhook