// Global app events
export const APP_SET_LOADING = "APP_SET_LOADING"


// Collection
export const COLLECTION_SET_STATE = "COLLECTION_SET_STATE"  
export const COLLECTION_CLEAN_FILTERS = "COLLECTION_CLEAN_FILTERS"

// EditCollection
export const EDIT_COLLECTIONS_GENERALES = "EDIT_COLLECTIONS_GENERALES"
export const EDIT_COLLECTIONS_RESET = "EDIT_COLLECTIONS_RESET"
export const EDIT_COLLECTIONS_RULES_CONFIG = "EDIT_COLLECTIONS_RULES_CONFIG"
export const EDIT_COLLECTIONS_METADATA_MAPPER = "EDIT_COLLECTIONS_METADATA_MAPPER"


// EditProject
export const EDIT_PROJECT_GENERALES = "EDIT_PROJECT_GENERALES"
export const EDIT_PROJECT_RESET = "EDIT_PROJECT_RESET"


// EditUser
export const EDIT_USER_GENERALES = "EDIT_USER_GENERALES"
export const EDIT_USER_RESET = "EDIT_USER_RESET"
export const EDIT_USER_ROLES = "EDIT_USER_ROLES"
export const EDIT_USER_PERMISSIONS = "EDIT_USER_PERMISSIONS"



// EditToken
export const EDIT_TOKEN_GENERALES = "EDIT_TOKEN_GENERALES"
export const EDIT_TOKEN_RESET = "EDIT_TOKEN_RESET"
export const EDIT_TOKEN_ROLES = "EDIT_TOKEN_ROLES"
export const EDIT_TOKEN_PERMISSIONS = "EDIT_TOKEN_PERMISSIONS"

export const EDIT_APP_MONITORING_GENERALES = "EDIT_APP_MONITORING_GENERALES"
export const EDIT_APP_MONITORING_RESET = "EDIT_APP_MONITORING_RESET"

// Sandbox
export const SANDBOX_ADD_DOC_REFERENCE = "SANDBOX_ADD_DOC_REFERENCE"
export const SANDBOX_SET_STATE = "SANDBOX_SET_STATE"
export const SANDBOX_SET_INCIDENT_DIALOG = "SANDBOX_SET_INCIDENT_DIALOG"

// EditIncident
export const EDIT_INCIDENT_GENERALES = "EDIT_INCIDENT_GENERALES"
export const EDIT_INCIDENT_RESET = "EDIT_INCIDENT_RESET"

// EditWebhook
export const EDIT_WEBHOOK_GENERALES = "EDIT_WEBHOOK_GENERALES"
export const EDIT_WEBHOOK_RESET = "EDIT_WEBHOOK_RESET"

