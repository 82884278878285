import React, { useContext } from 'react'
import AppContext from '../state/AppContext'
import { Divider, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import APIInvoke from '../utils/APIInvoke';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import UserContext from '../state/UserContext';
import GrantContent from './GrantContent';
import * as Permissions from '../utils/Permissions'
import LayersIcon from '@mui/icons-material/Layers';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import WebhookIcon from '@mui/icons-material/Webhook';

const Aside = (props) => {

    const { appContext, setAppContext } = useContext(AppContext)
    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate()

    const changeProject = (e) => {
        const newProject = e.target.value
        if (newProject === "_new") {
            navigate(`/projects`)
        } else {
            setAppContext({ ...appContext, project: newProject })
            navigate(`/projects/${newProject}/collections`)
        }

    }

    const selectDefaultProject = async (event, project) => {
        event.stopPropagation()

        const request = {
            defaultProject: project.name
        }

        const response = await (await APIInvoke.invokePUT(`/users/settings`, request)).json()

        if (response.ok) {
            setUser({
                ...user,
                settings: {
                    ...user.settings,
                    defaultProject: project.name
                }
            })
        }

    }

    return (
        <aside id="aside" className="bg-dark-900 ">
            <div className="flex flex-col gap-8 bg-dark-900 h-full">
                <div className="flex px-4 border-b border-dark-400 h-16 p-4 items-center gap-2">
                    <img src="/brand/goback-logo-white.png" alt="goback" className="h-10" />
                </div>

                <div className="grid px-4">
                    <FormControl variant="outlined" size="small" className="">
                        <InputLabel htmlFor="project" >Select project</InputLabel>
                        <Select
                            id="project"
                            name="project"
                            size="small"
                            label="project"
                            className="bg-dark-500 outline-red border border-dark-300"
                            value={appContext.project || ""}
                            onChange={changeProject}
                        >
                            <MenuItem disabled value=""><em>Select a project</em></MenuItem>
                            {appContext.projects.map(project => (
                                <MenuItem key={project.id} value={project.name}>
                                    <div className="flex items-center justify-between w-full">
                                        <ListItemText primary={project.name} />
                                        <Tooltip title="Set as default project">
                                            <Checkbox

                                                size="small"
                                                checked={user?.settings?.defaultProject === project.name}
                                                onClick={e => selectDefaultProject(e, project)}
                                                icon={<StarBorderIcon className="text-gray-200" />}
                                                checkedIcon={<StarIcon />}
                                            />
                                        </Tooltip>
                                    </div>
                                </MenuItem>

                            ))}
                            <GrantContent permisos={[Permissions.PROJECTS_W]} >
                                <MenuItem onClick={() => navigate(`/projects`)} value="_new" className="text-green"><em className="text-green text-sm"><AddIcon fontSize="small" /> Nuevo</em></MenuItem>
                            </GrantContent>
                        </Select>
                    </FormControl>
                </div>

                <Divider />


                <div className="grid gap-4 px-4">
                    <p className="text-gray-700 text-sm font-bold">DATA MANAGEMENT</p>
                    <GrantContent permisos={[Permissions.COLLECTIONS_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/collections`} >
                            <LayersIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Collections</span>
                        </Link>
                    </GrantContent>
                    <GrantContent permisos={[Permissions.COLLECTIONS_ALERTS_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/collections/notifications`} >
                            <CrisisAlertIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Collections Alerts</span>
                        </Link>
                    </GrantContent>
                </div>

                <div className="grid gap-4 px-4">
                    <p className="text-gray-700 text-sm font-bold">AUDIT</p>
                    <GrantContent permisos={[Permissions.INCIDENTS_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/incidents`} >
                            <NearbyErrorIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Incidents</span>
                        </Link>
                    </GrantContent>
                </div>


                {/*<div className="grid gap-4 px-4">
                    <p className="text-gray-700 text-sm font-bold">MONITORING</p>
                    <GrantContent permisos={[Permissions.APP_MONITORING_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/monitoring/apps`} >
                            <MonitorHeartIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">App Monitoring</span>
                        </Link>
                    </GrantContent>
                </div>*/}

                {/*<div className="grid gap-4 px-4">
                    <p className="text-gray-700 text-sm font-bold">OBSERVABILITY</p>
                    <GrantContent permisos={[Permissions.APP_MONITORING_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/monitoring/apps`} >
                            <GrainIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Transactional logs</span>
                        </Link>
                    </GrantContent>
                </div>*/}



                <div className="grid gap-4 px-4">
                    <p className="text-gray-700 text-sm font-bold">ADMINISTRATION</p>
                    <GrantContent permisos={[Permissions.USERS_A]}>
                        <Link className="flex justify-start items-center gap-1" to={`/users`} >
                            <SupervisedUserCircleIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Users</span>
                        </Link>
                    </GrantContent>

                    <GrantContent permisos={[Permissions.AUDIT_LOGS_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/auditlogs`} >
                            <FingerprintIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Audit Logs</span>
                        </Link>
                    </GrantContent>
                    {/*<GrantContent permisos={[Permissions.CONFIG_PROPERTIES_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/config-properties`} >
                            <PinIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Config Properties</span>
                        </Link>
                    </GrantContent>*/}

                    <GrantContent permisos={[Permissions.WEBHOOKS_R]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/webhooks`} >
                            <WebhookIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Webhooks</span>
                        </Link>
                    </GrantContent>

                    <GrantContent permisos={[Permissions.PROJECTS_W]}>
                        <Link className="flex justify-start items-center gap-1" to={`/projects/${appContext.project}/edit`} >
                            <SettingsIcon className="text-green2-700" fontSize="small" />
                            <span className="capitalize text-gray-400 font-bold text-md">Project settings</span>
                        </Link>
                    </GrantContent>
                </div>
            </div>
        </aside>
    )

}

export default Aside;