import {
    EDIT_WEBHOOK_RESET,
    EDIT_WEBHOOK_GENERALES
} from '../../../redux/redux-const';
import toast from 'react-hot-toast'
import store from '../../../redux/store'
import { setLoading } from '../../../template/app-actions';
import APIInvoke from "../../../utils/APIInvoke"


const getState = () => {
    return store.getState().pages.editWebhook
}

const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

export const toggleDeleteDialog = (event, document) => {
    event.stopPropagation();
    merge(EDIT_WEBHOOK_GENERALES, {
        deleteDialog: {
            show: !getState().deleteDialog.show,
            document: document
        }
    })
}


export const init = async (project, webhookId) => {
    const rawResponse = await APIInvoke.invokeGET(`/projects/${project}/webhooks/${webhookId}`)
    const response = await rawResponse.json();

    const state = getState();
    if (response.ok) {
        merge(EDIT_WEBHOOK_GENERALES, {
            new: false,
            id: response.body.id,
            name: response.body.name,
            url: response.body.url,
            resources: state.resources.map(resource => ({
                ...resource,
                checked: response.body.resourceTypes.includes(resource.key)
            })),
            headers: response.body.headers.map(header => ({
                id: crypto.randomUUID(),
                name: header.name,
                value: header.value,
            })),
            enabled: response.body.enabled
        })

    } else {
        toast.error(response.message)
    }
}

export const onRemoveWebhook = async (e, project, webhookId) => {
    try {
        setLoading(true)
        const state = getState();
        const rawResponse = await APIInvoke.invokeDELETE(`/projects/${project}/webhooks/${webhookId}/docs/${state.deleteDialog.document.documentId}`);
        const response = await rawResponse.json();
        store.dispatch({ type: EDIT_WEBHOOK_GENERALES, payload: { deleteDialog: { show: false, document: undefined } } })
        if (response.ok) {
            toast.success("Webhook removed successfully")
        } else {
            toast.error("Error removing webhook")
        }
    } catch (error) {
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}

export const reset = () => {
    store.dispatch({ type: EDIT_WEBHOOK_RESET })
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value

    if(type === "checkbox") {
        value = action.target.checked
    }

    merge(EDIT_WEBHOOK_GENERALES, { [name]: value })
}


export const onRowClick = (event, row, project, navigate) => {
    navigate(`/projects/${project}/collections/${row.collection}/documents/${row.documentId}`, { state: { backLink: -1 } })
}


export const save = async (event, project, webhookId, navigate) => {
    try {
        event.preventDefault();
        setLoading(true);

        const state = getState();
        const request = {
            id: state.id || undefined,
            name: state.name,
            url: state.url,
            resourceTypes: state.resources.filter(resource => resource.checked).map(resource => resource.key),
            headers: state.headers.map(header => ({ name: header.name, value: header.value })),
            enabled: state.enabled,
        };


        const rawResponse = state.new ?
            await APIInvoke.invokePOST(`/projects/${project}/webhooks`, request) :
            await APIInvoke.invokePUT(`/projects/${project}/webhooks/${webhookId}`, request);

        const response = await rawResponse.json();
        if (response.ok) {
            store.dispatch({ type: EDIT_WEBHOOK_GENERALES, payload: { id: response.body.id } })
            toast.success("Webhook saved successfully")
            navigate(`/projects/${project}/webhooks/${response.body.id}/edit`)
        } else {
            toast.error(response.message)
        }

    } catch (error) {
        console.error(error)
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}

export const updateResources = (event) => {
    const { name, checked } = event.target;
    const state = getState();
    merge(EDIT_WEBHOOK_GENERALES, { resources: state.resources.map(resource => ({ ...resource, checked: resource.key === name ? checked : resource.checked })) })
}

export const addHeader = () => {
    const state = getState();
    merge(EDIT_WEBHOOK_GENERALES, {
        headers: [
            ...state.headers,
            {
                id: crypto.randomUUID(),
                name: "<header name>",
                value: "<header value>"
            }
        ]
    })
}

export const onHeaderUpdate = (newRow, oldRow) => {
    const { id, name, value } = newRow;
    const state = getState();
    merge(EDIT_WEBHOOK_GENERALES, {
        headers: state.headers.map(header => header.id === id ? { ...header, name, value } : header)
    })
    return newRow;
}

export const removeHeader = (id) => {
    const state = getState();
    merge(EDIT_WEBHOOK_GENERALES, {
        headers: state.headers.filter(header => header.id !== id)
    })
}

export const fetchDocumentRulesBindings = async (project, webhookId) => {
    try {
        setLoading(true)
        const rawResponse = await APIInvoke.invokeGET(`/projects/${project}/collections/webhook/${webhookId}`)
        const response = await rawResponse.json();
        merge(EDIT_WEBHOOK_GENERALES, {
            ruleBindings: response.body
        })
    } catch (error) {
        console.error(error)
        toast.error(error.message)
    } finally {
        setLoading(false)
    }

}

