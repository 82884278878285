import './App.css';
import DocumentDiff from './pages/document-diff';
import Collections from './pages/collections';
import Collection from './pages/collection';
import { Provider } from 'react-redux'
import store from './redux/store'
import theme from "./theme";
import { createBrowserRouter, RouterProvider, useNavigate, useLocation, createRoutesFromElements, Route, Routes, Outlet } from "react-router-dom";
import Document from './pages/document';
import UserContext from './state/UserContext';
import AppContext from './state/AppContext';
import { ThemeProvider } from '@mui/material';
import ScreenDebug from './template/ScreenDebug';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Projects from './pages/projects';
import Project from './pages/project/Project';
import Diff from './pages/diff';
import Template from './template/Template';
import EditCollection from './pages/edit-collection';
import EditUser from './pages/edit-user';
import Users from './pages/users';
import Login from './pages/login';
import EditProject from './pages/edit-project/EditProject';
import EditToken from './pages/edit-token';
import APIInvoke from './utils/APIInvoke';
import Restore from './pages/restore';
import Profile from './pages/profile/Profile';
import GrantContent from './template/GrantContent';
import * as Permissions from './utils/Permissions'
import Home from './pages/home/Home';
import AppMonitoring from './pages/app-monitoring/AppMonitoring';
import EditAppMonitor from './pages/edit-app-monitor';
import CollectionsAlerts from './pages/collections-alerts';
import TLog from './pages/tlog/TLog';
import Auditlogs from './pages/auditlogs/Auditlogs';
import AuditlogDetail from './pages/auditlog-detail/AuditLogDetail';
import { SANDBOX_SET_STATE } from './redux/redux-const';
import Incidents from './pages/incidents';
import EditIncident from './pages/edit-incident';
import Webhooks from './pages/webhooks';
import EditWebhook from './pages/edit-webhook';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' >
      <Route path="login" element={<Login />} />
      <Route path="restore" element={<Restore />} />
      <Route element={<Template />}>
        <Route path="/" element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="projects" element={<GrantContent permisos={[Permissions.PROJECTS_W]} children={<Projects />} />} />
        <Route path="projects/:project" element={<GrantContent permisos={[Permissions.PROJECTS_W]} children={<Project />} />} />
        <Route path="projects/:project/edit" element={<GrantContent permisos={[Permissions.PROJECTS_W]} children={<EditProject />} />} />
        <Route path="projects/:project/tokens/new" element={<GrantContent permisos={[Permissions.TOKENS_A]} children={<EditToken />} />} />
        <Route path="projects/:project/collections" element={<GrantContent permisos={[Permissions.COLLECTIONS_R]} children={<Collections />} />} />
        <Route path="projects/:project/collections/notifications" element={<GrantContent permisos={[Permissions.COLLECTIONS_ALERTS_R]} children={<CollectionsAlerts />} />} />
        <Route path="projects/:project/collections/:collection" element={<GrantContent permisos={[Permissions.COLLECTIONS_R]} children={<Collection />} />} />
        <Route path="projects/:project/collections/:collection/edit" element={<GrantContent permisos={[Permissions.COLLECTIONS_W]} children={<EditCollection />} />} />
        <Route path="projects/:project/collections/:collection/documents/:documentId" element={<GrantContent permisos={[Permissions.COLLECTIONS_R]} children={<Document />} />} />
        <Route path="projects/:project/collections/:collection/documents/:documentId/diff" element={<GrantContent permisos={[Permissions.COLLECTIONS_R]} children={<Diff />} />} />
        <Route path="projects/:project/documents/:documentId/diff" element={<GrantContent permisos={[Permissions.COLLECTIONS_R]} children={<DocumentDiff />} />} />

        <Route path="projects/:project/monitoring/apps" element={<GrantContent permisos={[Permissions.APP_MONITORING_R]} children={<AppMonitoring />} />} />
        <Route path="projects/:project/monitoring/apps/new" element={<GrantContent permisos={[Permissions.APP_MONITORING_R]} children={<EditAppMonitor />} />} />
        <Route path="projects/:project/monitoring/apps/:appMonitorId/edit" element={<GrantContent permisos={[Permissions.APP_MONITORING_R]} children={<EditAppMonitor />} />} />

        <Route path="users" element={<GrantContent permisos={[Permissions.USERS_A]} children={<Users />} />} />
        <Route path="users/:user/edit" element={<GrantContent permisos={[Permissions.USERS_A]} children={<EditUser />} />} />

        <Route path="projects/:project/auditlogs" element={<GrantContent permisos={[Permissions.AUDIT_LOGS_R]} children={<Auditlogs />} />} />
        <Route path="projects/:project/auditlogs/:auditlogId" element={<GrantContent permisos={[Permissions.AUDIT_LOGS_R]} children={<AuditlogDetail />} />} />

        <Route path="projects/:project/tlogs/:traceId" element={<GrantContent permisos={[Permissions.TRANSACTIONAL_LOGS_R]} children={<TLog />} />} />


        <Route path="projects/:project/incidents" element={<GrantContent permisos={[Permissions.INCIDENTS_R]} children={<Incidents />} />} />
        <Route path="projects/:project/incidents/new" element={<GrantContent permisos={[Permissions.INCIDENTS_W]} children={<EditIncident />} />} />
        <Route path="projects/:project/incidents/:incidentId/edit" element={<GrantContent permisos={[Permissions.INCIDENTS_W]} children={<EditIncident />} />} />

        <Route path="projects/:project/webhooks" element={<GrantContent permisos={[Permissions.WEBHOOKS_R]} children={<Webhooks />} />} /> 
        <Route path="projects/:project/webhooks/:webhookId" element={<GrantContent permisos={[Permissions.WEBHOOKS_W]} children={<EditWebhook />} />} />
        <Route path="projects/:project/webhooks/new" element={<GrantContent permisos={[Permissions.WEBHOOKS_W]} children={<EditWebhook />} />} />
        <Route path="projects/:project/webhooks/:webhookId/edit" element={<GrantContent permisos={[Permissions.WEBHOOKS_W]} children={<EditWebhook />} />} />

        {/* 
        <Route path="projects/:project/webhooks" element={
          <GrantContent permisos={[Permissions.INCIDENTS_R]}>
            <Webhooks />
          </GrantContent>
        } />
        */}

      </Route>
    </Route>
  )
);


function App() {

  const [user, setUser] = useState({
  });

  const [appContext, setAppContext] = useState({
    template: {
      width: 320
    },
    navbar: {
      open: window.innerWidth >= 1024 ? true : false,
    },
    taskbar: {
      open: false
    },
    loading: false,
    projects: [],
    project: ""
  });

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if(isReady && user.id !== undefined) {
      loadContext()
    }
  }, [isReady])  

  const loadContext = async () => {
    const rawResponse = await APIInvoke.invokeGET(`/projects/${appContext.project}/sandbox`)
    const response = await rawResponse.json()
    if(response.ok) {
      store.dispatch({ type: SANDBOX_SET_STATE, payload: { docReferences: response.body.docReferences }  })
    } 
  }

  const init = async () => {
    try {
      const token = window.localStorage.getItem("token")
      if (token) isTokenExpired(token)
      if (token) {
        const loginResponse = await (await APIInvoke.invokePOST(`/users/login`)).json()
        const body = loginResponse.body

        //const projectResponse = await (await APIInvoke.invokeGET(`/projects`)).json()
        const projects = loginResponse.body.projects

        //setProjects(projectResponse.body.items)

        if (loginResponse.ok) {
          window.localStorage.setItem("token", body.token);
          setUser({
            ...body.user,
            roles: body.roles,
            permissions: body.permissions
          })

          let defaultProject = null
          const location = window.location.pathname.split("/")
          if (location.length >= 3 && location[1] === 'projects' && location[2] !== '_new') {
            defaultProject = projects.find(x => x.name === location[2].trim())
          } else if (projects.length > 0) {
            defaultProject = projects.find(x => x.name === loginResponse.body.user.settings?.defaultProject)
            if (!defaultProject) {
              defaultProject = projects[0]
            }
          }

          setAppContext({
            ...appContext,
            projects: projects,
            project: defaultProject?.name
          })

          /*
          if (projects.length === 0) {
            console.log("window.location.pathname", window.location.pathname)
            if (window.location.pathname !== '/projects/_new/edit') {
              window.location = "/projects/_new/edit"
              return;
            }
          }
          */
        } else {
          window.localStorage.removeItem("token")
          window.location = "/login"
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setReady(true)
    }
  }


  /*useEffect(() => {
    const token = window.localStorage.getItem("token")
    if (token) isTokenExpired(token)
    if (token) {
      APIInvoke.invokePOST(`/users/login`)
        .then((res) => res.json())
        .then((res) => {
          if (res.ok) {
            window.localStorage.setItem("token", res.body.token);
            setUser({
              ...res.body.user,
              roles: res.body.roles,
              permissions: res.body.permissions
            });
          } else {
            window.localStorage.removeItem("token")
            window.location = "/login"
          }
        })
        .catch((err) => setReady(true))
        .finally(() => {
          setReady(true)
        });
    } else {
      setReady(true);
    }
  }, []);*/

  /*
  useEffect(() => {
    const location = window.location.pathname.split("/")
    if (location.length >= 3 && location[1] === 'projects' && location[2] !== '_new') {
      const project = location[2].trim()
      setAppContext({
        ...appContext,
        project: project
      })
    }
  }, [window.location.pathname])
  */

  const isTokenExpired = (token) => {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    const isExpity = (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    if (isExpity) {
      window.localStorage.removeItem("token")
      window.location = "/login"
    }
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <AppContext.Provider value={{ appContext, setAppContext }}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ScreenDebug />
            <Toaster position="bottom-center" reverseOrder={false} toastOptions={{ duration: 5000, sticky: true, style: { padding: "16px" }, error: { style: { background: "#FAA0A0" } }, success: { style: { background: "#ECFFDC" } } }} />
            {isReady && (
              <RouterProvider router={router} />
            )}
          </ThemeProvider>
        </Provider>
      </AppContext.Provider>
    </UserContext.Provider>
  )
}

export default App;
