import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import AppContext from '../../state/AppContext'
import { addHeader, onHeaderUpdate, removeHeader, save, updateGenerales, updateResources } from './store/edit-webhook-actions'
import { DataGrid } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';

const headerColumns = [
    {
        field: 'name',
        editable: true,
        headerClassName: 'bg-gray-200',
        headerName: 'NAME',
        flex: 50,
    }, {
        field: 'value',
        editable: true,
        headerClassName: 'bg-gray-200',
        headerName: 'VALUE',
        flex: 50,
    }, {
        field: 'ACTIONS',
        editable: true,
        headerClassName: 'bg-gray-200',
        headerName: '',

        flex: 10,
        align: 'right',
        renderCell: (params) => (
            <IconButton onClick={() => removeHeader(params.row.id)}>
                <DeleteIcon className="hover:text-red-500" />
            </IconButton>
        )
    }
]

const EditWebhookGenerals = (props) => {

    const state = useSelector(state => state.pages.editWebhook);
    const navigate = useNavigate();
    const { appContext } = useContext(AppContext);
    const project = appContext.project;
    const { webhookId } = useParams();

    return (
        <form onSubmit={(e) => save(e, project, webhookId, navigate)}>
            <div className="border-b border-gray-300 p-4 bg-gray-100">
                <p>Resources</p>
                <small className="text-gray-600">Select the resources you want to receive notifications from</small>
            </div>
            <div className="grid gap-4 p-4">
                <div className="grid md:grid-cols-1 gap-4">
                    {state.resources.map(resource => (
                        <div key={resource.name} className="flex items-center">
                            <div>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            name={resource.name}
                                            value={resource.checked || false}
                                            control={<Checkbox checked={resource.checked || false} name={resource.key} />}
                                            label={
                                                <p className='flex gap-2'>{resource.name}
                                                    {resource.warn && (
                                                        <div className="flex items-center">
                                                            <Tooltip title={resource.warnMessage}>
                                                                <WarningIcon className="text-yellow-500" />
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </p>
                                            }
                                            onChange={updateResources}
                                        />
                                    </FormGroup>
                                </FormControl>
                                <p className="text-sm text-gray-600">{resource.description}</p>
                            </div>
                        </div>
                    ))}

                </div>

                <div className="border-t border-gray-300 py-4 mt-8">
                    <p >Webhook endpoint</p>
                    <small className="text-gray-600">Configure the webhook endpoint to receive notifications</small>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                        label="Name"
                        name="name"
                        required
                        type="name"
                        value={state.name}
                        onChange={updateGenerales}
                    />

                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <TextField
                        label="URL"
                        name="url"
                        required
                        type="url"
                        value={state.url}
                        onChange={updateGenerales}
                        helperText="The URL to send the webhook notifications by POST method" />

                </div>

                <div>
                    <Box sx={{ height: 300 }}>
                        <DataGrid
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSorting
                            rows={state.headers}
                            columns={headerColumns}
                            processRowUpdate={onHeaderUpdate}
                            hideFooterPagination={true}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick
                            slots={{
                                footer: () => (
                                    <div className="border-t p-2 bg-gray-200">
                                        <Button type="button" variant="contained" className="text-dark-500 !bg-gray-900" onClick={() => addHeader({ name: "", value: "" })}>ADD HEADER</Button>
                                    </div>
                                )
                            }}
                        />
                    </Box>
                </div>

                <div>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                            <FormControlLabel
                                name="enabled"
                                value={state.enabled}
                                control={<Checkbox checked={state.enabled} />}
                                label="Enabled"
                                onChange={updateGenerales}
                            />
                        </FormGroup>
                    </FormControl>
                </div>

            </div>

            <div className="p-4 text-right bg-gray-100 border-t border-gray-300">
                <Button variant="contained" className="text-dark-500" type="submit">SAVE</Button>
            </div>
        </form>
    )
}

export default EditWebhookGenerals 