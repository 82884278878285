export const PROJECTS_R= "PROJECTS_R"
export const PROJECTS_W= "PROJECTS_W"
export const PROJECTS_D= "PROJECTS_D"

export const COLLECTIONS_R= "COLLECTIONS_R"
export const COLLECTIONS_W= "COLLECTIONS_W"
export const COLLECTIONS_D= "COLLECTIONS_D"

export const COLLECTIONS_ALERTS_R= "COLLECTIONS_ALERTS_R"
export const COLLECTIONS_ALERTS_W= "COLLECTIONS_ALERTS_W"
export const COLLECTIONS_ALERTS_D= "COLLECTIONS_ALERTS_D"

export const DOCUMENTS_R= "DOCUMENTS_R"
export const DOCUMENTS_W= "DOCUMENTS_W"
export const DOCUMENTS_D= "DOCUMENTS_D"

export const USERS_A= "USERS_A"
export const TOKENS_A= "TOKENS_A"

export const AUDIT_LOGS_R= "AUDIT_LOGS_R"   

export const APP_MONITORING_R= "APP_MONITORING_R"
export const APP_MONITORING_W= "APP_MONITORING_W"
export const APP_MONITORING_D= "APP_MONITORING_D"



export const CONFIG_PROPERTIES_R= "CONFIG_PROPERTIES_R"
export const CONFIG_PROPERTIES_W= "CONFIG_PROPERTIES_W"
export const CONFIG_PROPERTIES_D= "CONFIG_PROPERTIES_D"


export const TRANSACTIONAL_LOGS_R= "TRANSACTIONAL_LOGS_R"
export const TRANSACTIONAL_LOGS_W= "TRANSACTIONAL_LOGS_W"
export const TRANSACTIONAL_LOGS_D= "TRANSACTIONAL_LOGS_D"

export const CASES_R= "CASES_R"
export const CASES_W= "CASES_W"
export const CASES_D= "CASES_D"

export const INCIDENTS_R= "INCIDENTS_R"
export const INCIDENTS_W= "INCIDENTS_W"
export const INCIDENTS_D= "INCIDENTS_D"

export const WEBHOOKS_R= "WEBHOOKS_R"
export const WEBHOOKS_W= "WEBHOOKS_W"
export const WEBHOOKS_D= "WEBHOOKS_D"
