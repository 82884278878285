import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { onRemoveDocument, onRowClick, save, toggleDeleteDialog, updateGenerales } from './store/edit-incident-actions'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AppContext from '../../state/AppContext'
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog'

const columns = [
    {
        field: 'collection',
        headerName: 'Collection',
        type: 'string',
        align: 'left'
    }, {
        field: 'key',
        headerName: 'Key',
        type: 'string',
        align: 'left'
    }, {
        field: 'revision',
        headerName: 'Revision',
        type: 'string',
        align: 'left'
    }, {
        field: 'actions',
        headerName: 'Actions',
        type: 'string',
        align: 'left'
    }
]


const EditIncidentGenerals = () => {

    const state = useSelector(state => state.pages.editIncident);
    const navigate = useNavigate();
    const { appContext } = useContext(AppContext);
    const project = appContext.project;
    const { incidentId } = useParams();

    return (
        <>
            <ConfirmDialog title="Remove document" message="You are about to remove this document from the incident" open={state.deleteDialog.show} onClose={toggleDeleteDialog} onConfirm={(e) => onRemoveDocument(e, project, incidentId)} />
            <form>
                <div className="grid gap-4 p-4">
                    <div className="grid md:grid-cols-2 gap-4">
                        <TextField autoFocus label="Name" name="name" value={state.name} onChange={(e) => { updateGenerales(e) }} />
                    </div>

                    <div>
                        <FormControl variant="outlined" className="w-40"  >
                            <InputLabel htmlFor="status" required={true}>Status</InputLabel>
                            <Select
                                id="status"
                                name="status"
                                fullWidth={true}
                                label="Status"
                                value={state.status}
                                onChange={(e) => { updateGenerales(e) }}
                            >
                                <MenuItem disabled value=""><em>Seleccione</em></MenuItem>
                                <MenuItem value="OPEN">Open</MenuItem>
                                <MenuItem value="CLOSED">Closed</MenuItem>
                                <MenuItem value="CONFIRMED">Confirmed</MenuItem>
                                <MenuItem value="DISMISSED">Dismissed</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="grid md:grid-cols-2 gap-4">
                        <TextField label="Description" name="description" rows={5} multiline={true} value={state.description} onChange={(e) => { updateGenerales(e) }} />
                    </div>

                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((headCell) => (
                                                    <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                        <TableSortLabel >
                                                            {headCell.headerName}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.documentReferences.length > 0 ? state.documentReferences.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => onRowClick(event, row, project, navigate)}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell align="left">{row.collection}</TableCell>
                                                        <TableCell align="left">{row.documentKey}</TableCell>
                                                        <TableCell align="left" width={130}>{row.revision}</TableCell>
                                                        <TableCell width={130} >
                                                            <Tooltip title="Delete">
                                                                <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }) : (
                                                <TableRow className="p-4">
                                                    <TableCell colSpan={4} align="center" className="p-4">No documents added, <Link className="text-green-500" to={`/projects/${project}/collections`}>add documents</Link></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Box>
                    </div>
                </div>

                <div className="p-4 text-right bg-gray-100 border-t border-gray-300">
                    <Button variant="contained" className="text-dark-500" onClick={() => save(project, incidentId, navigate)}>SAVE</Button>
                </div>
            </form>
        </>
    )
}

export default EditIncidentGenerals