import toast from 'react-hot-toast'
import { EDIT_INCIDENT_GENERALES, EDIT_INCIDENT_RESET } from '../../../redux/redux-const'
import store from '../../../redux/store'
import { setLoading } from '../../../template/app-actions'

import APIInvoke from '../../../utils/APIInvoke'
import { fromISOToLocal } from '../../../utils/DateUtilsUTC'


const merge = (type, param) => {
    store.dispatch({
        type,
        payload: {
            ...getState(),
            ...param
        }
    })
}

const getState = () => {
    return store.getState().pages.editIncident
}

export const reset = () => {
    store.dispatch({ type: EDIT_INCIDENT_RESET })
}

export const init = (project, incidentId) => {
    fetchIncident(project, incidentId)
}

export const fetchIncident = async (project, incidentId) => {
    try {
        setLoading(true)
        const rawResponse = await APIInvoke.invokeGET(`/projects/${project}/incidents/${incidentId}`)
        const response = await rawResponse.json()
        const incident = {
            loading: true,
            new: false,
            id: response.body.id,
            name: response.body.name,
            description: response.body.description,
            status: response.body.status,
            createdBy: response.body.createdBy && fromISOToLocal(response.body.createdBy),
            createdAt: response.body.createdAt && fromISOToLocal(response.body.createdAt),
            documentReferences: response.body.documentReferences
        }
        store.dispatch({ type: EDIT_INCIDENT_GENERALES, payload: { ...incident } })
    } catch (error) {
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}



export const save = async (project, incidentId, navigate) => {
    try {
        setLoading(true);

        const state = getState();

        const request = {
            name: state.name,
            description: state.description,
            status: state.status
        };


        const rawResponse = state.new ?
            await APIInvoke.invokePOST(`/projects/${project}/incidents`, request) : 
            await APIInvoke.invokePUT(`/projects/${project}/incidents/${incidentId}`, request);

        const response = await rawResponse.json();
        if (response.ok) {
            store.dispatch({ type: EDIT_INCIDENT_GENERALES, payload: { id: response.body.id } })
            toast.success("Incident updated successfully")
            navigate(`/projects/${project}/incidents/${response.body.id}/edit`)
        } else {
            toast.error("Error updating incident")
        }

    } catch (error) {
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}

export const updateGenerales = (action) => {
    const name = action.target.name
    const type = action.target.type
    let value = action.target.value


    if (type === "checkbox") {
        value = action.target.checked
    }

    console.log(name, type, value)
    merge(EDIT_INCIDENT_GENERALES, { [name]: value })
}

export const onRowClick = (event, row, project, navigate) => {
    navigate(`/projects/${project}/collections/${row.collection}/documents/${row.documentId}`, { state: { backLink: -1 } })
}

export const toggleDeleteDialog = (event, document) => {
    event.stopPropagation();
    merge(EDIT_INCIDENT_GENERALES, {
        deleteDialog: {
            show: !getState().deleteDialog.show,
            document: document
        }
    })
}

export const onRemoveDocument = async (e, project, incidentId) => {
    try {
        setLoading(true)
        const state = getState();
        const rawResponse = await APIInvoke.invokeDELETE(`/projects/${project}/incidents/${incidentId}/docs/${state.deleteDialog.document.documentId}`);
        const response = await rawResponse.json();
        store.dispatch({ type: EDIT_INCIDENT_GENERALES, payload: { deleteDialog: { show: false, document: undefined } } })
        if (response.ok) {
            toast.success("Document removed successfully")
        } else {
            toast.error("Error removing document")
        }
    } catch (error) {
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}


