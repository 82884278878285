import {
    SANDBOX_ADD_DOC_REFERENCE,
    SANDBOX_SET_STATE,
    SANDBOX_SET_INCIDENT_DIALOG
} from "../redux-const";

const initialState = {
    open: false,
    openIncidentMenu: false,
    openClearSandbox: false,
    incidentDialog: {
        open: false,
        openExist: false,
        name: "",
        description: "",
        incidents: [],
        incident: null
    },
    docReferences: []   
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case SANDBOX_SET_INCIDENT_DIALOG:
            return {
                ...state,
                incidentDialog: {
                    ...state.incidentDialog,
                    ...payload
                }
            }
        case SANDBOX_ADD_DOC_REFERENCE:
            return {
                ...state,
                docReferences: [...state.docReferences, payload]
            }
        case SANDBOX_SET_STATE:
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
};

export default reducer;
