import { ButtonGroup, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, FormControl, Grow, IconButton, InputLabel, MenuItem, MenuList, Paper, Popper, Select, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import AppContext from '../../state/AppContext';
import { addToIncident, clearSandbox, createIncident, deleteSandboxDocument, getIncidents, getState, handleClose, openSandboxDocument, setIncidentDialogState, setOpenClearSandbox, setOpenIncidentMenu, showSandbox } from './sandbox-actions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default function Sandbox() {

    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const navigate = useNavigate();
    const sandbox = getState()
    const anchorRef = React.useRef(null);

    useEffect(() => {
        if (sandbox.incidentDialog.openExist) {
            getIncidents(project)
        }
    }, [sandbox.incidentDialog.openExist, project])


    return (
        <>
            <Dialog open={sandbox.incidentDialog.open} onClose={() => setIncidentDialogState({ open: false })} className="" >
                <form onSubmit={(e) => createIncident(e, project)}>
                    <DialogTitle sx={{ m: 0, p: 2 }} className="bg-dark-800 w-[500px]">
                        <p className="text-gray-500">Create a new incident</p>
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => setIncidentDialogState({ open: false })} className="!right-[5px] !top-[12px] !absolute" >
                        <HighlightOffIcon className="text-gray-500" />
                    </IconButton>
                    <DialogContent dividers>
                        <div className="grid gap-4">
                            <TextField
                                required
                                placeholder='Enter a name for the incident'
                                value={sandbox.incidentDialog.name}
                                onChange={(e) => setIncidentDialogState({ name: e.target.value.replace(/[^a-zA-Z0-9\s]/g, '') })}
                                label="Incident name"
                                inputProps={{
                                    minLength: 5,
                                    maxLength: 100,
                                    pattern: "[A-Za-z0-9\\s]+"
                                }}
                            />

                            <TextField
                                required
                                placeholder='Describe the incident in detail'
                                value={sandbox.incidentDialog.description}
                                onChange={(e) => setIncidentDialogState({ description: e.target.value.replace(/[^a-zA-Z0-9\s]/g, '') })}
                                label="Description"
                                multiline
                                rows={5}
                                inputProps={{
                                    minLength: 0,
                                    maxLength: 2000,
                                    pattern: "[A-Za-z0-9\\s]+"
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions className="bg-gray-200">
                        <Button autoFocus onClick={() => setIncidentDialogState({ open: false })}>Cancel</Button>
                        <Button variant="contained" type="submit" >Create new incident</Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog open={sandbox.incidentDialog.openExist} onClose={() => setIncidentDialogState({ openExist: false })} className="" >
                <form onSubmit={(e) => addToIncident(e, project)}>
                    <DialogTitle sx={{ m: 0, p: 2 }} className="bg-dark-800 w-[500px]">
                        <p className="text-gray-500">Add to existing incident</p>
                    </DialogTitle>
                    <IconButton aria-label="close" onClick={() => setIncidentDialogState({ openExist: false })} className="!right-[5px] !top-[12px] !absolute" >
                        <HighlightOffIcon className="text-gray-500" />
                    </IconButton>
                    <DialogContent dividers>
                        <div className="grid gap-4">
                            <FormControl size="medium">
                                <InputLabel id="incident-label">Revision</InputLabel>
                                <Select
                                    label="Incident"
                                    placeholder='Select an incident'
                                    value={sandbox.incidentDialog.incident}
                                    onChange={(e) => setIncidentDialogState({ incident: e.target.value })}
                                >
                                    {sandbox.incidentDialog.incidents.map(item => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions className="bg-gray-200">
                        <Button autoFocus onClick={() => setIncidentDialogState({ openExist: false })}>Cancel</Button>
                        <Button variant="contained" type="submit" >Add to incident</Button>
                    </DialogActions>
                </form>
            </Dialog>


            <Dialog open={sandbox.openClearSandbox} onClose={() => setOpenClearSandbox(false)} className="" >
                <DialogTitle sx={{ m: 0, p: 2 }} className="bg-dark-800 w-[500px]">
                    <p className="text-gray-500">Clear sandbox</p>
                </DialogTitle>
                <IconButton aria-label="close" onClick={() => setOpenClearSandbox(false)} className="!right-[5px] !top-[12px] !absolute" >
                    <HighlightOffIcon className="text-gray-500" />
                </IconButton>
                <DialogContent dividers>
                    <div className="grid gap-4">
                        <p>Are you sure you want to clear the sandbox?</p>
                    </div>
                </DialogContent>
                <DialogActions className="bg-gray-200">
                    <Button autoFocus onClick={() => setOpenClearSandbox(false)}>CANCEL</Button>
                    <Button variant="contained" type="button" className="!bg-red-500" onClick={() => clearSandbox(project)} >ACCEPT</Button>
                </DialogActions>
            </Dialog>


            <Drawer
                anchor="right"
                open={sandbox.open}
                onClose={() => showSandbox(false)}
                className="!border-dark-900"
            >
                <div className='grid gap-4 !w-[500px] h-full bg-dark-900 '>
                    <div >
                        <div className="flex flex-row justify-between items-center bg-dark-600 h-[62px] p-2 mb-2">
                            <p className='flex flex-row gap-2 items-center text-white text-lg font-bold  px-2 '><MoveToInboxIcon /> Sandbox</p>
                            <IconButton onClick={() => showSandbox(false)} className="!text-green-500">
                                <CloseIcon className="text-white" />
                            </IconButton>
                        </div>


                        <div className="grid gap-4 px-4 mt-4">
                            <p className="text-orange-500 text-sm bg-dark-700 p-2 rounded-md ">Sandbox is a workspace where you can add shortcuts to documents for auditing.</p>
                            <div className="grid gap-2">
                                {sandbox.docReferences.map(item => (
                                    <div key={item.documentId} className='flex flex-row gap-4 justify-between items-center bg-dark-500 rounded-md'>
                                        <Button className="block w-full !text-left" LinkComponent={Link} onClick={() => openSandboxDocument(navigate, project, item)}>
                                            <div className='text-gray-500 text-left w-full px-2'>
                                                <div className="m-0" ><span className="text-lg">{item.documentKey}<sup className="text-xs text-green-500 lowercase">v{item.revision}</sup></span>/<span className="text-xs">{item.collection}</span></div>
                                            </div>
                                        </Button>
                                        <IconButton onClick={(e) => deleteSandboxDocument(e, project, item)} className="!text-red-500">
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                            {sandbox.docReferences.length > 0 && (
                                <div className="flex flex-row gap-4">
                                    <ButtonGroup
                                        variant="contained"
                                        ref={anchorRef}
                                        aria-label="Button group with a nested menu"
                                    >
                                        <Button
                                            aria-controls={sandbox.openIncidentMenu ? 'split-button-menu' : undefined}
                                            aria-expanded={sandbox.openIncidentMenu ? 'true' : undefined}
                                            aria-label="add to incident"
                                            aria-haspopup="menu"
                                            onClick={(prevOpen) => setOpenIncidentMenu((prevOpen) => !prevOpen)} >Add to incident <ArrowDropDownIcon />
                                        </Button>
                                    </ButtonGroup>
                                    <Button onClick={() => setOpenClearSandbox(true)}>Clear sandbox</Button>

                                    <Popper
                                        sx={{ zIndex: 1 }}
                                        open={sandbox.openIncidentMenu}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{
                                                    transformOrigin:
                                                        placement === 'bottom' ? 'right top' : 'right bottom',
                                                }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={(event) => handleClose(event, anchorRef)}>
                                                        <MenuList id="split-button-menu" autoFocusItem>
                                                            <MenuItem onClick={() => setIncidentDialogState({ open: true })} >New incident    </MenuItem>
                                                            <MenuItem onClick={() => setIncidentDialogState({ openExist: true })} >Add to existing incident</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
}

