import { Box, Breadcrumbs, Paper, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SourceIcon from '@mui/icons-material/Source';
import { useSelector } from 'react-redux';
import { reset, init } from './store/edit-incident-actions';
import AppContext from '../../state/AppContext';
import Header from '../../template/Header';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ForumIcon from '@mui/icons-material/Forum';
import EditIncidentGenerals from './EditIncidentGenerals';
import EditIncidentInteractions from './EditIncidentInteractions';

const EditIncident = (props) => {


    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { incidentId } = useParams()
    const [tab, setTab] = useState(0)
    const state = useSelector(state => state.pages.editIncident)

    useEffect(() => {
        if (incidentId !== undefined ) {
            init(project, incidentId)
        }

        return () => reset()
    }, [incidentId])

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className="">
            <Header title={<span className="capitalize">{state.name}</span>} >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600">
                <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Incidents</span></Typography>
                    <Typography ><span className="text-gray-600">{incidentId}</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content">
                <div className="grid gap-8" >
                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%' }}>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={tab} onChange={changeTab} aria-label="basic tabs example">
                                        <Tab label="General" icon={<SourceIcon />} iconPosition="start" />
                                        {
                                            //<Tab label="Interactions" disabled={state.new} icon={<ForumIcon />} iconPosition="start" />
                                        }
                                    </Tabs>
                                </Box>
                            </Box>
                                {tab === 0 && <EditIncidentGenerals />}
                                {
                                    //tab === 1 && <EditIncidentInteractions />
                                }
                        </Paper>
                    </Box>
                </div>
            </div>
        </div>
    )

}


export default EditIncident