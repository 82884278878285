import store from '../../redux/store';
import { SANDBOX_SET_INCIDENT_DIALOG, SANDBOX_SET_STATE } from '../../redux/redux-const';
import APIInvoke from '../../utils/APIInvoke';
import toast from 'react-hot-toast';
import { setLoading } from '../../template/app-actions';

export const getState = () => {
    return store.getState().context.sandbox
}

export const showSandbox = (open) => {
    store.dispatch({ type: SANDBOX_SET_STATE, payload: { open } })
}

export const deleteSandboxDocument = async (e, project, documentRef) => {
    const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/sandbox/${documentRef.documentId}`)).json()
    if (response.ok) {
        toast.success("Document removed from sandbox")
    } else {
        toast.error(response.message)
    }
    store.dispatch({ type: SANDBOX_SET_STATE, payload: { docReferences: response.body.docReferences } })
}

export const setOpenIncidentMenu = (open) => {
    store.dispatch({ type: SANDBOX_SET_STATE, payload: { openIncidentMenu: open } })
}

export const openSandboxDocument = (navigate, project, documentRef) => {
    showSandbox(false)
    navigate(`/projects/${project}/collections/${documentRef.collection}/documents/${documentRef.documentId}`)
}

export const getIncidents = async (project) => {
    try {
        setLoading(true)
        const rawResponse = await APIInvoke.invokeGET(`/projects/${project}/incidents?status=OPEN&size=999`)
        const response = await rawResponse.json()

        store.dispatch({ type: SANDBOX_SET_INCIDENT_DIALOG, payload: { incidents: response.body.items } })
    } catch (error) {
        toast.error(error.message)
    } finally {
        setLoading(false)
    }
}

export const setIncidentDialogState = (newState) => {
    store.dispatch({ type: SANDBOX_SET_INCIDENT_DIALOG, payload: newState })
}

export const addToIncident = async (e, project) => {
    e.preventDefault()
    const state = getState();
    try {
        const request = {
            documentReferences: state.docReferences.map(item => ({
                collection: item.collection,
                documentId: item.documentId,
                documentKey: item.documentKey,
                revision: item.revision,
                observations: item.observations
            }))
        }
        const rawResponse = await APIInvoke.invokePOST(`/projects/${project}/incidents/${state.incidentDialog.incident}/docs`, request);
        const response = await rawResponse.json();
        if (response.ok) {
            toast.success("Incident created successfully")
            clearSandbox(project);
        } else {
            toast.error(response.message)
        }
        setIncidentDialogState({ openExist: false, name: "", description: "" })
    } catch (error) {
        toast.error(error.message)
    }
}

export const handleClose = (event, anchorRef) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
    }
    setOpenIncidentMenu(false);
};

export const createIncident = async (e, project) => {
    e.preventDefault()
    const state = getState();
    try {
        const request = {
            name: state.incidentDialog.name,
            description: state.incidentDialog.description,
            status: "OPEN",
            documentReferences: state.docReferences.map(item => ({
                collection: item.collection,
                documentId: item.documentId,
                documentKey: item.documentKey,
                revision: item.revision,
                observations: item.observations
            }))
        }

        const rawResponse = await APIInvoke.invokePOST(`/projects/${project}/incidents`, request);
        const response = await rawResponse.json();
        if (response.ok) {
            toast.success("Incident created successfully")
            clearSandbox(project);
        } else {
            toast.error(response.message)
        }
        setIncidentDialogState({ open: false, name: "", description: "" })
    } catch (error) {
        toast.error(error.message)
    }
}

export const clearSandbox = async (project) => {
    const rawResponse = await APIInvoke.invokePUT(`/projects/${project}/sandbox/clear`)
    const response = await rawResponse.json()
    if (response.ok) {
        toast.success("Sandbox cleared successfully")
        store.dispatch({ type: SANDBOX_SET_STATE, payload: { docReferences: [] } })
        setOpenClearSandbox(false)
    } else {
        toast.error(response.message)
    }
}

export const setOpenClearSandbox = (open) => {
    store.dispatch({ type: SANDBOX_SET_STATE, payload: { openClearSandbox: open } })
}

