import {
    EDIT_INCIDENT_GENERALES,
    EDIT_INCIDENT_RESET
} from "../../../redux/redux-const";

const initialState = {
    //UI control
    loading: undefined,
    tab: 0,
    new: true,
    deleteDialog: {
        show: false,
        document: undefined
    },

    // Data
    id: undefined,
    name: "",
    description: "",
    status: "",
    createdBy: "",
    createdAt: "",
    documentReferences: []
}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case EDIT_INCIDENT_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_INCIDENT_RESET:
            return initialState
        default:
            return state
    }
};

export default reducer;
