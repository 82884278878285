import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react';
import { fetchDocumentRulesBindings } from './store/edit-webhook-actions';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const headerColumns = [
    {
        field: 'id',
        headerClassName: 'bg-gray-200',
        headerName: 'ID',
        flex: 50,
    }, {
        field: 'type',
        headerClassName: 'bg-gray-200',
        headerName: 'TYPE',
        flex: 50,
        renderCell: (params) => "COLLECTION"
    }, {
        field: 'name',
        headerClassName: 'bg-gray-200',
        headerName: 'NAME',
        flex: 50
    }, {
        field: 'link',
        headerClassName: 'bg-gray-200',
        headerName: 'LINK',
        flex: 50,
        renderCell: (params) => {
            return <Link to={`/projects/${params.row.project}/collections/${params.row.name}`}>
                <OpenInNewIcon />
            </Link>
        }
    }
]

const EditWebhookRulesBindings = ({ project, webhookId }) => {

    const state = useSelector(state => state.pages.editWebhook.ruleBindings)

    useEffect(() => {
        fetchDocumentRulesBindings(project, webhookId)
    }, [project, webhookId])
    


    return (
        <div>
            <div className="border-b border-gray-300 p-4 bg-gray-100">
                <small className="text-gray-600">Show all document rules bindings to this webhook</small>
            </div>
            <div className="grid gap-4 p-4">
                <div>
                    <Box sx={{ height: 300 }}>
                        <DataGrid
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSorting
                            rows={state}
                            columns={headerColumns}
                            hideFooterPagination={true}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                            }}
                            disableRowSelectionOnClick

                        />
                    </Box>
                </div>
            </div>
        </div>
    )
};

export default EditWebhookRulesBindings;
