import {
    EDIT_WEBHOOK_RESET,
    EDIT_WEBHOOK_GENERALES
} from "../../../redux/redux-const";
import WarningIcon from '@mui/icons-material/Warning';


const initialState = {
    loading: undefined,
    tab: 0,
    new: true,

    id: undefined,
    name: "",
    url: "",
    headers: [
        {
            id: crypto.randomUUID(),
            name: "Authorization",
            value: "Bearer 1234567890"
        }
    ],
    ruleBindings: [],
    resources: [
        {
            key: "USER",
            name: "User",
            description: "Notify when a user is created, updated or deleted",
            checked: false
        },
        {
            key: "DOCUMENT",
            name: "Document",
            description: "Notify when a document is created, updated or deleted",
            checked: false,
            warn: true,
            warnMessage: "Enable with caution as it may produce a massive amount of events"
        },
        {
            key: "PROJECT",
            name: "Project",
            description: "Notify when a project is created, updated or deleted",
            checked: false
        },
        {
            key: "COLLECTION",
            name: "Collection",
            description: "Notify when a collection is created, updated or deleted",
            checked: false
        },
        {
            key: "WEBHOOK",
            name: "Webhook",
            description: "Notify when a webhook is created, updated or deleted",
            checked: false
        }
    ],
    enabled: true

}


export const reducer = (state = initialState, action) => {
    const payload = action.payload;

    switch (action.type) {
        case EDIT_WEBHOOK_GENERALES:
            return {
                ...state,
                ...payload
            }
        case EDIT_WEBHOOK_RESET:
            return initialState
        default:
            return state
    }
};

export default reducer;
