import { Box, Breadcrumbs, Button, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import APIInvoke from '../../utils/APIInvoke'
import { Link, useNavigate } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../template/ConfirmDialog';
import { toast } from 'react-hot-toast';
import AppContext from '../../state/AppContext';
import { setLoading } from '../../template/app-actions';
import * as Permissions from '../../utils/Permissions'
import GrantContent from '../../template/GrantContent';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Header from '../../template/Header';
import { useSelector } from 'react-redux';

const columns = [
    {
        field: 'url',
        headerName: 'URL',
        type: 'string',
        align: 'left'
    }, {
        field: 'resourceTypes',
        headerName: 'Resources',
        type: 'string',
        align: 'left'
    }, {
        field: '',
        headerName: 'Actions',
        type: 'string',
        align: 'right'
    }
]

const Webhooks = (props) => {

    const navigate = useNavigate();
    const [data, setData] = useState(undefined)
    const { appContext } = useContext(AppContext)
    const project = appContext.project
    const { loading } = useSelector(state => state.pages.app)
    const [showDeleteDialog, setShowDeleteDialog] = useState({ open: false, payload: null })

    useEffect(() => {
        try {
            setLoading(true)
            init(data)
        } catch (error) {
            console.error("Projects.useEffect => ", error)
        } finally {
            setLoading(false)
        }
    }, [project])

    const init = async (newState) => {
        try {
            setLoading(true)
            const response = await (await APIInvoke.invokeGET(`/projects/${project}/webhooks`)).json()
            if (response.ok) {
                setData(response.body);
            } else {
                setData([]);
                toast.error(response.message)
            }
        } catch (error) {
            console.error("Error lo load page", error)
        } finally {
            setLoading(false)
        }
    }

    const onRowClick = (event, row) => {
        navigate(`/projects/${project}/webhooks/${row.id}/edit`, { state: { backLink: -1 } })
    }

    const onDelete = async () => {
        const response = await (await APIInvoke.invokeDELETE(`/projects/${project}/webhooks/${showDeleteDialog.payload.id}`)).json()
        if (response.ok) {
            setData(data.filter(x => x.id !== showDeleteDialog.payload.id))
            setShowDeleteDialog({ open: false, payload: null })
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
    }

    const toggleDeleteDialog = (event, payload) => {
        event.stopPropagation()
        setShowDeleteDialog({
            open: !showDeleteDialog.open,
            payload
        })
    }

    return (
        <div className="">
            <ConfirmDialog title="Delete webhook" message="This action is permanent and there will be no way to recover the webhook" open={showDeleteDialog.open} onClose={toggleDeleteDialog} onConfirm={onDelete} />
            <Header title="Webhooks" >
                <Breadcrumbs aria-label="breadcrumb" separator="/" className="!text-gray-600" >
                    <Typography ><HomeOutlinedIcon className="text-gray-600" fontSize="small" /></Typography>
                    <Typography ><span className="text-gray-600">Webhooks</span></Typography>
                </Breadcrumbs>
            </Header>

            <div className="content ">
                <div className="grid gap-8">
                    <div className="text-right">
                        <GrantContent permisos={[Permissions.WEBHOOKS_W]}>
                            <Button variant="contained" LinkComponent={Link} to={`/projects/${project}/webhooks/new`} state={{ backLink: -1 }}><AddIcon /> NEW WEBHOOK</Button>
                        </GrantContent>
                    </div>
                    <div >
                        <Box sx={{ width: '100%' }}>
                            <Paper sx={{ width: '100%', mb: 2 }}>
                                {loading && <LinearProgress size="sm" />}
                                <TableContainer>
                                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}  >
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((headCell) => (
                                                    <TableCell key={headCell.field} align={headCell.align} padding={'normal'} >
                                                        <TableSortLabel >
                                                            {headCell.headerName}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data !== undefined && data.length > 0 && data.map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => onRowClick(event, row)}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell align="left">{row.url}</TableCell>
                                                        <TableCell align="left">{row.resourceTypes.join(", ")}</TableCell>
                                                        <TableCell width={130} align="right">
                                                            <GrantContent permisos={[Permissions.WEBHOOKS_D]}>
                                                                <Tooltip title="Delete">
                                                                    <IconButton onClick={(event) => toggleDeleteDialog(event, row)} ><DeleteIcon className="hover:text-red" /></IconButton>
                                                                </Tooltip>
                                                            </GrantContent>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {data !== undefined && data.length == 0 && (
                                                <TableRow><TableCell colSpan={columns.length} align="center"><Typography>No data found</Typography></TableCell></TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {loading && <LinearProgress size="sm" />}
                            </Paper>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Webhooks 